@import url('https://fonts.cdnfonts.com/css/pacifico');
@import url('https://fonts.cdnfonts.com/css/brutel');

.contain {
    margin: 50px;
    margin-top: 250px;
    font-family: 'Brutel', sans-serif;
}

.medium_text {
    font-size: 20px;
}

.dear_name {
    font-family: 'Pacifico', sans-serif;
    font-size: 25px;
    letter-spacing: 3px;
    font-weight: 300;
}

.offer_title {
    text-align: center;
    font-size: 30px;
    font-family: 'Brutel', sans-serif;
}

.offer_content {
    font-size: 23px;
    font-family: 'Brutel', sans-serif;
    line-height: 1.8;
    font-weight: 600;
}

.offer_registrar {
    margin-top: 40px;
    font-size: 23px;
    font-weight: bold;

}

.acceptance_text {
    font-size: 30px;
}

.acceptance_content{
    font-size: 30px;
    font-weight: 700;
}