@media print {
    #printPageButton {
        display: none;
    }

    .hideSection {
        display: none;
    }
    .hideSectionSidebar{
        display: none;
    }
    .showSection {
        display: block;
    }

    .hideFooter {
        display: none !important;
    }

    #MainBody {
        margin-left: 0px !important;
    }
    .break-page {page-break-after: always;}
   
}

.showSection {
    display: none;
}

