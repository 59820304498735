/*@page {*/
/*    size: A4;*/
/*    margin: 0;*/
/*}*/
@media print {
    #printPageButton {
        display: none;
    }

    .hideSection {
        display: none;
    }

    .hideFooter {
        display: none !important;
    }

    #MainBody {
        margin-left: 0px !important;
    }

    .top-margin{
        margin-top: -50px;
    }
    .break-page {page-break-after: always;}

    .take-left {
        margin-left: -50px;
    }
}


.BarcodeContainer {
    font-family: sans-serif;
    text-align: center;
}

.item-data {
    display: flex;
    justify-content:  center;

}

.head{
    text-align: center;
}

.head-item {
    display: flex;
    justify-content: center;
}

.head-main{
    margin: auto;
    position: relative;

}


th, td {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}